<template>
  <div class="video-player">
    <video
      ref="videoElement"
      :src="videoSrc"
      :autoplay="autoplay"
      :loop="loop"
      :controls="controls"
      :width="width"
      :height="height"
    ></video>
  </div>
</template>

<script>
  export default {
    props: {
      videoSrc: {
        type: String,
        required: true,
      },
      autoplay: {
        type: Boolean,
        default: false,
      },
      loop: {
        type: Boolean,
        default: false,
      },
      controls: {
        type: Boolean,
        default: true,
      },
      width: {
        type: [String, Number],
        default: '100%',
      },
      height: {
        type: [String, Number],
        default: 'auto',
      },
    }
  };
</script>

<style scoped>
.video-player {
  width: 100%;
  height: 100%;
}
</style>