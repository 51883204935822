<template>
  <div class="course-content">
    <div class="chat-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/course.png`)" fit="contain"></el-image>
        <div class="head-title">职业规划课程体系</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <div class="chat-main">
      <div class="main-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/courseChat' }">第一阶段</el-breadcrumb-item>
          <el-breadcrumb-item>就业信息的收集与处理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="main-messages">
        <video-player :video-src="videoUrl" width="76%"></video-player>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '../../../components/VideoPlayer.vue'

export default {
  components: {
    VideoPlayer
  },
  data () {
    return {
      videoUrl: 'https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.7450405111705809ChatGPTzc.mp4',
      course_id: ''
    }
  },
  methods: {
    sendExit () {
      this.$emit('update-value')
    },
    init () {
      console.log(this.course_id)
    }
  },
  mounted () {
    this.init()
  },
  created () {
    this.course_id = this.$route.query.course_id
  }
}
</script>

<style lang="less" scoped>
.course-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-head {
    min-height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
  .chat-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    .main-head {
      min-height: 8%;
      display: flex;
      align-items: center;
      .el-breadcrumb__item {
        font-size: 18px;
      }
    }
    .main-messages {
      // flex: 1;
      text-align: center;
      background-color: #d4e5ff;
    }
  }
}
</style>